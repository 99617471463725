<template>
  <div class="mobile_view">
    <img src="/media/logos/logo.png" height="55px;" style="margin: 10px 78px" />
    &nbsp;
    <div
      style="
        text-align: center;
        margin-top: 0;
        margin-bottom: 1rem;
        width: 254px;
      "
    >
      <h1>Refine My Data</h1>
    </div>
    <br />
    <div
      style="
        text-align: center;
        margin-top: 0;
        margin-bottom: 1rem;
        width: 254px;
      "
    >
      <h3>
        RefineMyData does not support mobile phones. Please visit this page on a
        tablet or a computer.
      </h3>
      <br />
      <h3>
        If you wish to get in touch, please contact us via LiveChat by selecting
        the chat icon below.
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "Mobile",
};
</script>

<style>
.mobile_view {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
