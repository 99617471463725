import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import Swal from 'sweetalert2'
// import Cookies from "js-cookie";

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const FORGOT = 'forgot'
export const UPDATE_PASSWORD = 'updateUser'
export const SAVE_PASSWORD = 'savepassword'
export const UPDATE_AVATAR = 'updateAvatar'
export const UPDATE_USER_INFO = 'updateUserInfo'
export const UPDATE_USER_SETTING = 'updateUserSetting'
export const READ_USER_SETTING = 'readUserSetting'
export const CHANGE_PASSWORD = 'changepassword'
export const CHANGE_EMAIL = 'changeemail'
export const VERIFY_CHANGE_EMAIL = 'verifychangeemail'
export const VERIFY_APPROVE_PROCESS = 'verifyapproveprocess'

export const WRITE_JSON = 'writejson'
export const READ_JSON = 'readjson'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_USER_SETTING = 'setUserSetting'
export const SET_AUTH = 'setUser'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'

export const ADD_COUPON = 'add_coupon'
export const GET_COUPON = 'get_coupon'
export const DELETE_COUPON = 'delete_coupon'
export const UPDATE_LIMIT = 'update_limit'

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
        },
      })

      ApiService.post('login', credentials)
        .then(({ data }) => {
          if (credentials.remember) {
            this._vm.$cookies.set('credentials', credentials, '7d') // Save email for 1 day
            this._vm.$cookies.set('authToken', data.token, '7d')
          } else {
            this._vm.$cookies.remove('credentials') // Session cookie (no expiry)
          }

          Swal.close()
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          Swal.close()
          context.commit(SET_ERROR, response.data.error)
          reject(response.data.errors)
        })
    })
  },

  [FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
        },
      })

      ApiService.post('forgotpassword', credentials)
        .then(({ data }) => {
          Swal.close()
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          Swal.close()
          context.commit(SET_ERROR, response.data.error)
          reject(response.data.errors)
        })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
        },
      })

      ApiService.post('register', credentials)
        .then(({ data }) => {
          Swal.close()
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          Swal.close()
          context.commit(SET_ERROR, response.data.error)
          reject(response.data.errors)
        })
    })
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // Swal.fire({
      //     title: "Please Wait",
      //     allowOutsideClick: false,
      //     onOpen: () => { Swal.showLoading(); }
      // });

      ApiService.setHeader()
      ApiService.get('verify')
        .then(({ data }) => {
          // Swal.close();
          context.commit(SET_AUTH, data)
        })
        .catch(() => {
          // Swal.close();
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(PURGE_AUTH)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  [UPDATE_USER_INFO](context, payload, id) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
        },
      })

      ApiService.post('user/' + id + '/update', payload)
        .then(({ data }) => {
          Swal.close()
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          Swal.close()
          context.commit(SET_ERROR, response.data.error)
          reject(response.data.errors)
        })
    })

    //context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_USER_SETTING](context, payload) {
    return new Promise((resolve, reject) => {
      // Swal.fire({
      //     title: "Please Wait",
      //     allowOutsideClick: false,
      //     onOpen: () => { Swal.showLoading(); }
      // });

      ApiService.post('user/updatesetting', payload)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          // Swal.close();
          context.commit(SET_ERROR, response.data.error)
          reject(response.data.errors)
        })
    })

    //context.commit(SET_PERSONAL_INFO, payload);
  },
  [READ_USER_SETTING]() {
    ApiService.setHeader()

    // return new Promise((resolve, reject) => {
    //     ApiService.get("user/readsetting")
    //         .then(data => resolve(data))
    //         .catch(({ response }) => {
    //             reject(response.data.errors);
    //         });
    // });

    return ApiService.get('user/readsetting')
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        // Swal.close();
        this.commit(SET_ERROR, response.data.error)
        return
      })
  },
  [UPDATE_AVATAR](context, payload) {
    let data = new FormData()

    data.append('avatar', payload.file)

    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading()
        },
      })

      ApiService.post('user/' + payload.user.id + '/avatar', data)
        .then(({ data }) => {
          Swal.close()
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          Swal.close()
          context.commit(SET_ERROR, response.data.error)
          reject(response.data.errors)
        })
    })

    //context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload

    return ApiService.put('user/password', password).then(({ data }) => {
      context.commit(SET_PASSWORD, data)
      return data
    })
  },
  [SAVE_PASSWORD](context, payload) {
    const password = payload
    const passwordId = payload

    return ApiService.put('user/savepassword', password, passwordId).then(({ data }) => {
      // context.commit(SET_PASSWORD, data);
      return data
    })
  },
  [CHANGE_PASSWORD](context, payload) {
    const password = payload
    return ApiService.post('changepassword', password).then(({ data }) => {
      // context.commit(SET_PASSWORD, data);
      return data
    })
  },
  [WRITE_JSON](context, payload) {
    const jsonData = payload

    ApiService.setHeader()
    const data = ApiService.post('writejson', jsonData).then(({ data }) => {
      return data
    })
    return data
  },
  [READ_JSON]() {
    ApiService.setHeader()
    const data = ApiService.get('readjson').then(({ data }) => {
      return data
    })
    return data
  },
  [CHANGE_EMAIL](context, payload) {
    const new_email = payload
    const current_email = payload
    return ApiService.post('changeemail', new_email, current_email).then(({ data }) => {
      return data
    })
  },
  [VERIFY_CHANGE_EMAIL](context, payload) {
    const password = payload
    return ApiService.post('verifychangeemail', password).then(({ data }) => {
      return data
    })
  },
  [VERIFY_APPROVE_PROCESS](context, payload) {
    const password = payload
    return ApiService.post('verifyapproveprocess', password).then(({ data }) => {
      return data
    })
  },

  [ADD_COUPON](context, payload) {
    const coupon_details = payload
    return ApiService.post('add_coupon', coupon_details).then(({ data }) => {
      return data
    })
  },
  [GET_COUPON]() {
    ApiService.setHeader()
    const data = ApiService.get('get_coupon').then(({ data }) => {
      return data
    })
    return data
  },
  [DELETE_COUPON](context, payload) {
    const coupon_id = payload
    return ApiService.post('delete_coupon', coupon_id).then(({ data }) => {
      return data
    })
  },
  [UPDATE_LIMIT](context, payload) {
    const free_limit = payload
    return ApiService.post('update_limit', free_limit).then(({ data }) => {
      return data
    })
  },

  // [VIEW_EMAIL_PAGE](context, payload) {
  //     const password = payload;

  //     return ApiService.post("viewemailpage", password).then(({ data }) => {
  //         // context.commit(SET_PASSWORD, data);
  //         return data;
  //     })
  // },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
    if (state.user.token) {
      JwtService.saveToken(state.user.token)
    }
  },
  [SET_USER_SETTING](state, payload) {
    state.user.userData.onboarding = payload.onboarding
    state.user.userData.heatmap = payload.heatmap
    state.user.userData.onboarding_flag = payload.onboarding_flag
    //state.user.userData.triggerflag = payload.triggerflag;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
