import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'
import { VERIFY_AUTH } from '@/core/services/store/auth.module'
import { UPDATE_TASKS } from '@/core/services/store/tasks.module'

import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module'

//vue tour
//import VueTour from "vue-tour";
import ProgressBar from 'vuejs-progress-bar'
import VueEllipseProgress from 'vue-ellipse-progress'
import VueAnimateNumber from 'vue-animate-number'
import VueCircle from 'vue2-circle-progress'
import VueCookies from 'vue-cookies'
import VueApexCharts from 'vue-apexcharts'
import { FormSelectPlugin } from 'bootstrap-vue'

// Css removed from nod_modules and put in the assets folder and linked in the index.html OS 29-11-2022
//require("vue-tour/dist/vue-tour.css");
//Vue.use(VueTour);
Vue.use(ProgressBar)
Vue.use(VueCircle)
Vue.use(VueEllipseProgress)
Vue.use(VueAnimateNumber)
Vue.use(VueApexCharts)

Vue.use(VueCookies)

Vue.use(FormSelectPlugin)

Vue.config.productionTip = false
Vue.config.silent = true
Vue.component('apexchart', VueApexCharts)

// global variable
// Vue.prototype.$dashboard_tour = true;
// Vue.prototype.$reviewdata_heatmap = true;

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'
window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import vuetify from '@/core/plugins/vuetify'
import '@/core/plugins/portal-vue'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/highlight-js'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/metronic'
import '@/core/plugins/treeselect'
import '@mdi/font/css/materialdesignicons.css'

// API service init
ApiService.init()

router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH), store.dispatch(UPDATE_TASKS, { filter: '' })]).then(next)
  store.dispatch(RESET_LAYOUT_CONFIG)
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
