'use strict'

import KTUtil from './util'

// Component Definition
var KTDialog = function (options) {
  // Main object
  var the = this

  // Get element object
  var element
  var body = KTUtil.getBody()

  // Default options
  var defaultOptions = {
    placement: 'top center',
    type: 'loader',
    width: 100,
    state: 'default',
    message: 'Loading...',
  }

  ////////////////////////////
  // ** Private Methods  ** //
  ////////////////////////////

  var Plugin = {
    /**
     * Construct
     */

    construct: function (options) {
      Plugin.init(options)

      return the
    },

    /**
     * Handles subtoggle click toggle
     */
    init: function (options) {
      the.events = []

      // merge default and user defined options
      the.options = KTUtil.deepExtend({}, defaultOptions, options)

      the.state = false
    },

    /**
     * Show dialog
     */
    show: function () {
      Plugin.eventTrigger('show')

      element = document.createElement('DIV')
      KTUtil.setHTML(element, the.options.message)

      KTUtil.addClass(element, 'dialog dialog-shown')
      KTUtil.addClass(element, 'dialog-' + the.options.state)
      KTUtil.addClass(element, 'dialog-' + the.options.type)

      if (the.options.placement == 'top center') {
        KTUtil.addClass(element, 'dialog-top-center')
      }

      body.appendChild(element)

      the.state = 'shown'

      Plugin.eventTrigger('shown')

      return the
    },

    /**
     * Hide dialog
     */
    hide: function () {
      if (element) {
        Plugin.eventTrigger('hide')

        element.remove()
        the.state = 'hidden'

        Plugin.eventTrigger('hidden')
      }

      return the
    },

    /**
     * Trigger events
     */
    eventTrigger: function (name) {
      for (var i = 0; i < the.events.length; i++) {
        var event = the.events[i]

        if (event.name == name) {
          if (event.one == true) {
            if (event.fired == false) {
              the.events[i].fired = true
              return event.handler.call(this, the)
            }
          } else {
            return event.handler.call(this, the)
          }
        }
      }
    },

    addEvent: function (name, handler, one) {
      the.events.push({
        name: name,
        handler: handler,
        one: one,
        fired: false,
      })

      return the
    },
  }

  //////////////////////////
  // ** Public Methods ** //
  //////////////////////////

  /**
   * Set default options
   */

  the.setDefaults = function (options) {
    defaultOptions = options
  }

  /**
   * Check shown state
   */
  the.shown = function () {
    return the.state == 'shown'
  }

  /**
   * Check hidden state
   */
  the.hidden = function () {
    return the.state == 'hidden'
  }

  /**
   * Show dialog
   */
  the.show = function () {
    return Plugin.show()
  }

  /**
   * Hide dialog
   */
  the.hide = function () {
    return Plugin.hide()
  }

  /**
   * Attach event
   * @returns {KTToggle}
   */
  the.on = function (name, handler) {
    return Plugin.addEvent(name, handler)
  }

  /**
   * Attach event that will be fired once
   * @returns {KTToggle}
   */
  the.one = function (name, handler) {
    return Plugin.addEvent(name, handler, true)
  }

  // Construct plugin
  Plugin.construct.apply(the, [options])

  return the
}

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = KTDialog
}

export default KTDialog
